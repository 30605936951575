import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const query = graphql`
  query ($slug: String) {
    contentfulAuthor(slug: {eq: $slug}) {
      name
      profilePicture {
        id
        gatsbyImageData(width: 200, placeholder: BLURRED, formats: AUTO, quality: 100)
      }
    }
  }
`

const author = ({data : {contentfulAuthor: author}}) => {
  return (
    <div>
      Author is: {author.name}
      <GatsbyImage image={getImage(author.profilePicture)} />
    </div>
  )
}

export default author
